import React from 'react'
// import { Job } from '../components/Job'
// import { SEO } from '../components/SEO'
// import { JobLayout } from '../layouts/JobLayout'
import { Job as JobType } from '../../utils/greenhouse'
import JobPageTemplate from '../../templates/JobPageTemplate'

const FrontendDeveloper = () => {
  const job: JobType = {
    id: '1',
    title: "Senior Frontend Engineer",
    location: {
        name: "Poznań, Poland"
    },
    metadata: [
      {
        value: {
          min_value: "24500",
          max_value: "30500",
          unit: "PLN"
        },
        value_type: "",
        name: "Salary Range"
      }
    ],
    emailApply: true,
    ogImage: "https://jobs.fourthwall.com/images/og/frontend.png",
    content: `
      <div class="article">
        <div>
          <p><strong>Job Summary</strong></p>
          <p><span style="font-weight: 400;">We seek a thoughtful Senior Frontend Engineer to shape our web platform architecture and execution. You'll work directly with 2-6 engineers (out of our exceptional team of 40+ engineers) and collaborate with cross-functional teams to deliver exceptional web experiences for content creators.</span></p>
        </div>

        <div>
          <p><strong>What we expect:</strong></p>
          <ul>
          <li style="font-weight: 400;"><span style="font-weight: 400;"><strong data-renderer-mark="true">You have worked professionally with web technologies for 5+ years</strong> with experience on a single application long enough to see the consequences of your decisions,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;"><strong data-renderer-mark="true">Deep understanding of <a href="https://web.dev/baseline" target="_blank">web platform baseline</a></strong> and modern standards, including browser APIs and progressive enhancement,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;"><strong data-renderer-mark="true">Web performance and optimization</strong> as a core priority, with knowledge of modern loading strategies and performance monitoring,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;"><strong data-renderer-mark="true">Familiarity with common software and system design patterns and infrastructure</strong>, including APIs, cloud infrastructure tools, storage solutions, data structures, etc.</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;"><strong data-renderer-mark="true">Expertise in React</strong> and its ecosystem, with a strong appreciation for vanilla web capabilities and framework-agnostic solutions,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;"><strong data-renderer-mark="true">Proficiency in modern tooling</strong>, including build systems, testing frameworks (e.g., Playwright, Testing Library), and CI/CD pipelines,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;"><strong data-renderer-mark="true">Strong communication skills</strong>, with the ability to clearly articulate technical decisions and share knowledge effectively,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;"><strong data-renderer-mark="true">English language proficiency</strong> for clear and accessible communication, both in writing and speech,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Enthusiasm for <strong data-renderer-mark="true">pushing the boundaries of web platform capabilities</strong> and integrating cutting-edge techniques,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Knowledge of <strong data-renderer-mark="true">SEO, Node.js, Ruby on Rails, Kotlin, Docker, Google Tag Manager</strong> and cloud platforms is a plus.</span></li>
          </ul>
        </div>

        <div>
          <p><strong>Technologies in a nutshell:</strong></p>
          <p><span style="font-weight: 400;">JavaScript, TypeScript, React, CSS, Next.js, Testing Library, CSS-in-JS, Web APIs</span></p>
        </div>

        <div>
          <p><strong>Perks:</strong></p>
          <ul>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Salary 24 500 - 30 500 PLN (paid in USD, + VAT 0% as you'll be exporting your services to the US),</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Equity in our US-based company,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Remote work, flexible working hours, and access to our modern and comfortable office in Poznań (Jeżyce),</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">B2B agreement for an indefinite period with 26 days of paid vacation per year, health-related absence policy,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">An exciting and demanding project that you have a real influence on,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Work in a team of experienced experts and amazing people,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">The latest standards of used tools - current and supported frameworks, without working on outdated code,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Employer-paid perks (MultiSport, LUX MED + dental care, accounting support, educational budget, English lessons, team integrations, four months parental allowance),</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">MacBook Pro and any other equipment tailored to your needs.</span></li>
          </ul>
        </div>
      
        <div>
          <p><strong>Key Responsibilities:</strong></p>
          <ul>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Write clean, high-quality, <strong data-renderer-mark="true">testable</strong> code,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;"><strong data-renderer-mark="true">Take ownership</strong> of maintaining and <strong data-renderer-mark="true">suggesting improvements</strong> to the codebase that enhance the speed of delivering new features while ensuring direction and high code quality standards are upheld,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Develop and execute frontend architecture aligned with modern <strong data-renderer-mark="true"> web standards</strong> and performance,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Ship exceptional web experiences to over, <strong data-renderer-mark="true">100k creators</strong>,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Make technical decisions that balance the use of React with the new capabilities of web platforms,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Conduct regular frontend performance reviews and optimize <strong data-renderer-mark="true">user experiences</strong>.</span></li>
          </ul>
        </div>

        <div>
          <p><strong>About us:</strong></p>
          <p><span style="font-weight: 400;">Fourthwall gives creators the freedom to focus on creating. Over 100,000 creators are using Fourthwall to power their shops and memberships.</span></p>
          <p><span style="font-weight: 400;">We enable creators (YouTubers, Instagrammers, Podcasters, etc.- anyone with a large online audience) to make money by opening beautiful, fully customized online shops where they can sell merchandise, accept donations, and offer memberships. Our mission is to make the money-making side of content creation easy and let creators focus on what matters: creating great content and interacting with their fans.</span></p>
          <p><span style="font-weight: 400;">Our culture is built around putting our customers, the creators, first. If you are talented, hardworking, and love helping others, we would love to talk to you about joining our team!</span></p>
          <p><span style="font-weight: 400;">By choosing our offer, you can join a team of experts in application architecture, programming languages (Kotlin and Ruby enthusiasts), and, most importantly, extensive e-commerce business experience.</span></p>
          <p><span style="font-weight: 400;">If you want to learn more about us, and how we work, you can peek at <a href="https://jobs.fourthwall.com" target="_blank">jobs.fourthwall.com</a>. We also suggest that you get to know our <a href="https://cdn.fourthwall.com/public/fourthwall/Fourthwall%20Values.pdf" target="_blank">Company Values</a>.</span></p>
        </div>

        <div>
          <p><strong>Recruitment process:</strong></p>
          <ol>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Sending CV (pdf) + Initial Phone Call (20-45 minutes) - We'd like you to submit your portfolio or a list of recent projects you are proud of (please highlight the parts for which you are responsible). For privacy reasons, you can protect access with a password. The introductory call will be a brief conversation to get to know you, discuss your background, and align expectations for the future role.</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Recruitment task carried out at a time selected by you (remotely), we will pay PLN 600 net for your time (regardless of the interview result)</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Final Meeting (in person or remotely) at the Fourthwall HQ with our team (w/2-3 engineers from our team) (1-2 hours)</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">On average, Your and our final decision will be made within three business days</span></li>
          </ol>
        </div>

        <div>
          <p><span style="font-weight: 400;">It's also worth noting that we encourage anyone interested to apply, even if they don't meet all requirements. You know what they say about missing shots...</span></p>
          <p><span style="font-weight: 400;">Most of our team is based in and around Poznań, and we love the ease of spontaneous meetings in our Poznań office. While the position doesn't require daily attendance, you'll always have your place in the beautiful office whenever you want to drop by!</span></p>

          <p><span style="font-weight: 400;"><em data-renderer-mark="true">We are an equal-opportunity employer. We value a diverse workforce and an inclusive culture. We encourage applications from all qualified individuals without regard to race, color, religion, gender, sexual orientation, gender identity or expression, age, national origin, marital status, disability, and veteran status.</em></span></p>
        </div>

      </div>
    `
  }

  return (
    <div>
      <JobPageTemplate pageContext={{
          job
      }} />
    </div>
  )
}

export default FrontendDeveloper