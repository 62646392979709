import React from 'react'
// import { Job } from '../components/Job'
// import { SEO } from '../components/SEO'
// import { JobLayout } from '../layouts/JobLayout'
import { Job as JobType } from '../../utils/greenhouse'
import JobPageTemplate from '../../templates/JobPageTemplate'

const Senior3dGeneralist = () => {
  const job: JobType = {
    id: '1',
    title: "Senior 3D Generalist",
    location: {
        name: "Poznań, Poland"
    },
    metadata: [
      {
        value: {
          min_value: "15000",
          max_value: "23000",
          unit: "PLN"
        },
        value_type: "",
        name: "Salary Range"
      }
    ],
    emailApply: true,
    ogImage: "https://jobs.fourthwall.com/images/og/3d.png",
    content: `
      <div class="article">
        <div>
          <p><strong>About us</strong></p>
          <p><span style="font-weight: 400;">Fourthwall powers over 100,000 creators' shops and memberships, helping YouTubers, Instagrammers, and Podcasters monetize their brands through customized online shops. Our mission is to make the business side of content creation seamless, letting creators focus on what they do best.</span></p>
          <p><span style="font-weight: 400;">If you want to learn more about us, and how we work, you can peek at <a href="https://jobs.fourthwall.com" target="_blank">jobs.fourthwall.com</a>. We also suggest that you get to know our <a href="https://cdn.fourthwall.com/public/fourthwall/Fourthwall%20Values.pdf" target="_blank">Company Values</a>.</span></p>
        </div>

        <div>
          <p><strong>About the role</strong></p>
          <p><span style="font-weight: 400;">We're seeking an innovative 3D Technical Artist/Pipeline Developer to improve our product visualization pipeline. This role sits at the intersection of creative and technical, focusing on developing and optimizing our 3D product visualization workflow while exploring cutting-edge technologies to enhance our creator experience.</span></p>
          <p><span style="font-weight: 400;">We are looking for a candidate who is comfortable with a hybrid office role, which is expected to require being in the office for 1 to 3 days, based in our location in Poznań, Poland.</span></p>
        </div>

        <div>
          <p><strong>What you will do</strong></p>
          <ul>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Optimize and innovate our existing product visualization pipeline</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Develop automation solutions for photogrammetry and 3D mapping processes</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Research and implement new technologies (LIDAR, spatial mapping, AI vision)</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Create tools and scripts to streamline repetitive workflow tasks</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Bridge the gap between photo production and our product designer</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Challenge and improve existing processes</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Collaborate with product and engineering teams to deliver high-quality mockup experiences</span></li>
          </ul>
        </div>

        <div>
          <p><strong>Required Skills</strong></p>
          <ul>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Experience with photogrammetry workflows and mapping techniques</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">A proactive mindset with problem-solving skills and a strong sense of ownership</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">The ability to work independently and take initiative</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Python scripting capabilities</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Experience with Blender or Houdini</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Strong retopology, UV mapping skills</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Curiosity about emerging technologies and their applications</span></li>
          </ul>
        </div>

        <div>
          <p><strong>Nice to Have</strong></p>
          <ul>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Familiarity with AI tools and computer vision</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Understanding of modern web technologies</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Photography skills</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Background in e-commerce or product visualization</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Experience with automated testing and CI/CD pipelines</span></li>
          </ul>
        </div>
        
        <div>
          <p><strong>Perks</strong></p>
          <ul>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Salary 15 000 - 23 000 PLN (paid in USD, + VAT 0% as you'll be exporting your services to the US),</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Equity in our US-based company,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">B2B agreement for an indefinite period with 26 days of paid vacation per year, health-related absence policy,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">An exciting and demanding project that you have a real influence on,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Work in a team of experienced experts and amazing people,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">The latest standards of used tools - current and supported frameworks, without working on outdated code,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">The modern office in Poznań, Jeżyce equipped with lots of flora, standing desks, and even an amateur gym,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">A lot of perks (MultiSport, LUX MED + dental care, educational budget, integrations),</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">MacBook Pro and any other equipment tailored to your needs.</span></li>
          </ul>
        </div>

        <div>
          <p><strong>What Makes You Stand Out</strong></p>
          <p><span style="font-weight: 400;">We're looking for someone who:</span></p>
          <ul>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Takes ownership and drives initiatives without constant oversight</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Questions the status quo and proposes innovative solutions</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Balances technical excellence with practical delivery</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Stays current with emerging technologies and industry trends</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Values both creative and technical aspects of production</span></li>
          </ul>
        </div>

        <div>
          <p><strong>The Interview process:</strong></p>
          <ol>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Portfolio & CV review</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Initial discussions with our design team</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Paid ( 300 PLN ) 3D challenge to see how we work together</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Interviews with other team members out</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Final conversation about role specifics and expectations</span></li>
          </ol>
        </div>

        <div>
          <p><span style="font-weight: 400;"><strong>It's also worth noting that we encourage anyone who may be interested to apply, even if you don't meet 100% of the qualifications above.</strong> We may have overlooked, under-counted, or over-indexed on some of these qualifications. So, please — apply. You know what they say about missing shots...</span></p>
          <p><span style="font-weight: 400;"><em data-renderer-mark="true">We are an equal-opportunity employer. We value a diverse workforce and an inclusive culture. We encourage applications from all qualified individuals without regard to race, color, religion, gender, sexual orientation, gender identity or expression, age, national origin, marital status, disability, and veteran status.</em></span></p>
        </div>

      </div>
    `
  }

  return (
    <div>
      <JobPageTemplate pageContext={{
          job
      }} />
    </div>
  )
}

export default Senior3dGeneralist